import React from "react"
import "../assets/css/who.css"
//import Logo from "../images/logo.png"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import ShoppingContainer from '../components/shoppingcontainer';
import { Link } from "gatsby"

// import appStoreIcon from "../images/app-store-icon.png"
// import googlePlayIcon from "../images/google-pay-icon.png"
// import fbIcon from "../images/fb-icon.svg"
// import twitterIcon from "../images/twitter.svg"
// import instacon from "../images/insta-icon.svg"


// import video from "../images/video-icon.svg"

import lineImage from "../images/line2.png"

// import mailIcon from "../images/mail-icon.svg"
// import callIcon from "../images/call-icon.svg"
// import livechat from "../images/live-chat-icon.svg"

import Layout from "../components/layout"

// import mapIcon from "../images/map.svg"
// import mobileImg from "../images/mobile-img.png"

// import newslatter from "../images/newslatter.svg"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"

// import whoBanner from "../images/who/who-Banner-New.svg"
import whoBanner from "../images/who/whobanner.svg"
//import vision from "../images/who/vision.svg"
import mission from "../images/who/mission.svg"
import mailService from "../images/who/mailService.svg"
//import mapicon from "../images/who/map.svg"

// import pathRight from "../images/who/pathright.svg"
//import pathsub from "../images/who/subtraction.svg"
import whatwedo from "../images/who/servicecontarct-who-we-are.svg"
import mwhatwedo from "../images/who/whatwedo-mobile.svg"
import SEO from "../components/seo"

const WhoWeArePage = (props) => (
  <Layout rest={props} layoutType="main">
    <SEO title="Servicecontract.com - Who we are" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
    <div className="chat-content" style={{ display: 'none' }}>
      <div className="chat-content-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
          <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
              <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
      <div className="chat-box">

        <div className="chatbox-inner">
          <div className="chat-profile">
            <img className="" src={chatProfile} alt="" />
          </div>
          <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
          <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
        </div>
        <img className="chat-bottom" src={chatbottom} alt="" />
      </div>

    </div>

    {/* --------------banner--------- */}
    <section className="banner whoWe-banner">
      <div className="banner-inner">
        <div className="whobanner-img">
          <img src={whoBanner} alt="" className="" />
        </div>

        <div className="container">
          <h1>Every road traveled is a journey.</h1>
          <h5>The car repair experience needed something innovative.</h5>
        </div>
      </div>
    </section>

    {/* --------------- process-work-container--------- */}


    <section className="vision-container">
      <div className="container">
        {/* <div className="ourVision">
          <img src={vision} alt="" />
          <h3 className="title">Our Vision</h3>
          <p className="subtitle">To offer a revolutionary way to keep your vehicle on the road.</p>
        </div> */}
        <div className="ourMission">
          <img src={mission} alt="" />
          {/* <h3 className="title">Our Mission</h3> */}
          <h3 className="mission">Freedom and transparency, plain and simple.  We strive to give you the freedom of a service contract that does not lock you in to an extended term, all while being completely transparent every step of the way.</h3>
        </div>
        <div className="drive-title hide hide-mobile">
          <h3 className="title title2">Drivers needed transparency.</h3>
          <h3 className="title title2">Consumers needed trust.</h3>
          <h3 className="title title2">We all needed convenience.</h3>
        </div>
        <h3 className="title">That's what set us on our journey.</h3>

        <div className="journey-container">
          <div className="">
          <img src={whatwedo} alt="" />
            {/* <div className="box first right">
              <div className="journey-logo">
                <img src={Logo} alt="" />
              </div>
              <div className="mapicon-content">
                <img src={mapicon} alt="" />
                <h5>2017</h5>
              </div>
              <div className="leftside">

                <div className="leftside-inner">

                </div>
              </div>
              <div className="rightside">
                <div className="rightside-inner">
                  <ul>
                    <li>
                      <h6>January</h6>
                      <p>Launched Servicecontract Dealer Services, providing integrated co-branded platforms for auto dealerships.</p>
                    </li>
                    <li>
                      <h6>March</h6>
                      <p>Released our award-winning 1Quote™ shopping experience</p>
                    </li>
                    <li>
                      <h6>June</h6>
                      <p>Launched partnership with SafeAuto</p>
                    </li>
                    <li>
                      <h6>September</h6>
                      <p>Announced strategic partnership with CUNA Mutual Group, providing mechanical repair coverage for credit union members</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="box left">
              <div className="mapicon-content">
                <img src={mapicon} alt="" />
                <h5>2018</h5>
              </div>
              <div className="leftside">
                <div className="leftside-inner">
                    
                  <ul>
                    <li>
                      <h6>January</h6>
                      <p>Launched Servicecontract Dealer Services, providing integrated co-branded platforms for auto dealerships.</p>
                    </li>
                    <li>
                      <h6>March</h6>
                      <p>Released our award-winning 1Quote™ shopping experience</p>
                    </li>
                    <li>
                      <h6>June</h6>
                      <p>Launched partnership with SafeAuto</p>
                    </li>
                    <li>
                      <h6>September</h6>
                      <p>Announced strategic partnership with CUNA Mutual Group, providing mechanical repair coverage for credit union members</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="rightside">
                <div className="rightside-inner">

                </div>
              </div>
            </div> */}

            {/* <div className="box three right">

              <div className="journey-logo">
                <img src={Logo} alt="" />
              </div>
              <div className="mapicon-content">
                <img src={mapicon} alt="" />
                <h5>2019</h5>
              </div>
              <img src={pathsub} alt="" className="bottom-sub" />
              <div className="leftside">
                <div className="leftside-inner">

                </div>
              </div>
              <div className="rightside">
                <div className="rightside-inner">
                  <ul>
                    <li>
                      <h6>January</h6>
                      <p>Launched servicecontract.com to bring a simple solution to all vehicle owners.</p>
                    </li>
                    <li>
                      <h6>June</h6>
                      <p>Partnered exclusively with the best administrative team in the industry.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>


      </div>

    </section>
    <section className="m-journey-container">
      <div className="journey-container-innner">
      <img src={mwhatwedo} alt="" />
        {/* <div className="box-content">
          <div className="mapicon-content">
            <img src={mapicon} alt="" />
            <h5>2017</h5>
          </div>
          <ul>
            <li>
              <h6>January</h6>
              <p>Launched Servicecontract Dealer Services, providing integrated co-branded platforms for auto dealerships.</p>
            </li>
            <li>
              <h6>March</h6>
              <p>Released our award-winning 1Quote™ shopping experience</p>
            </li>
            <li>
              <h6>June</h6>
              <p>Launched partnership with SafeAuto</p>
            </li>
            <li>
              <h6>September</h6>
              <p>Announced strategic partnership with CUNA Mutual Group, providing mechanical repair coverage for credit union members</p>
            </li>
          </ul>
        </div>

        <div className="box-content">
          <div className="mapicon-content">
            <img src={mapicon} alt="" />
            <h5>2018</h5>
          </div>
          <ul>
            <li>
              <h6>January</h6>
              <p>Launched Servicecontract Dealer Services, providing integrated co-branded platforms for auto dealerships.</p>
            </li>
            <li>
              <h6>March</h6>
              <p>Released our award-winning 1Quote™ shopping experience</p>
            </li>
            <li>
              <h6>June</h6>
              <p>Launched partnership with SafeAuto</p>
            </li>
            <li>
              <h6>September</h6>
              <p>Announced strategic partnership with CUNA Mutual Group, providing mechanical repair coverage for credit union members</p>
            </li>
          </ul>
        </div> */}

        {/* <div className="box-content">
          <div className="mapicon-content">
            <img src={mapicon} alt="" />
            <h5>2019</h5>
          </div>
          <ul>
            <li>
              <h6>January</h6>
              <p>Launched servicecontract.com to bring a simple solution to all vehicle owners.</p>
            </li>
            <li>
              <h6>June</h6>
              <p>Partnered exclusively with the best administrative team in the industry.</p>
            </li>
          </ul>
        </div> */}
      </div>
    </section>

    <section className="forwardContact-container">
      <div className="container">
        <div className="forwardContact-inner">
          <div className="left">
            <img src={mailService} alt="" />
          </div>
          <div className="right">
            <h3 className="title">Move forward with ServiceContact </h3>
            <p className="subtitle">Get in touch to learn how selling extended vehicle service plans through our DIGITAL PLATFORM can help you drive additional income, brand loyalty and competitive advantage.</p>
            {/* <button className="btn primary">Email us</button> */}
            <Link className="btn covered-btn" to="/contact">Email us</Link>
          </div>
        </div>
      </div>
    </section>
    <ShoppingContainer className="bg" />
    {/* <NewsLatter /> */}
    <div className="break-section">
      <img src={lineImage} alt="line" />
    </div>
    <Footer />
  </Layout>
)

export default WhoWeArePage
